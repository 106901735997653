import React from 'react';
import ReactDOM from 'react-dom/client';

import AppWrapper from './AppWrapper';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <AppWrapper />
    </React.StrictMode>,
);

reportWebVitals();

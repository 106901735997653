import { lazy, Suspense } from 'react';

import Loading from './components/Loading';

const App = lazy(() => import('./App'));

function AppWrapper() {
    return (
        <Suspense fallback={<Loading />}>
            <App />
        </Suspense>
    );
}

export default AppWrapper;
